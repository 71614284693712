@import url("https://fonts.googleapis.com/css2?family=Bonheur+Royale&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Play&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Alata&family=Anek+Devanagari:wght@100&family=Hind+Vadodara:wght@400;500&family=Merienda&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Athiti:wght@200;300&family=Cinzel:wght@400;500;600&display=swap');

body {
  background-color: #e3e6e9be;
  padding: 0;
  font-family: 'Athiti', sans-serif;
font-weight: 300;
  
  margin: 0;
}

.font1 {
  font-family: 'Alata', sans-serif;
}
.font2 {
  font-family: 'Anek Devanagari', sans-serif;
}
.font3 {
  font-family: 'Hind Vadodara', sans-serif;
}
.font4{
  font-family: 'Playfair Display', serif;
}
.font5{
  font-family: 'Cinzel', serif;
}
.font6{
  font-family: 'Athiti', sans-serif;

}
.inner1 {
  border-radius: 25px;
  background-color: #dde1e7;
  box-shadow: -5px -5px 10px #ffffff73, 5px 5px 10px rgba(94, 104, 121, 0.288);

  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to bottom right,
    rgb(212, 212, 212),
    rgba(243, 240, 240, 0.644)
  );
}
.inner2 {
  border-radius: 25px;
  background-color: #dde1e7;
  box-shadow: -5px -5px 10px #ffffff73,
    inset 5px 5px 10px rgba(94, 104, 121, 0.288);

  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to bottom right,
    rgb(212, 212, 212),
    rgba(243, 240, 240, 0.644)
  );
}
.inner3 {
  border-radius: 25px;
  background-color: #dde1e7;
  box-shadow: -5px -5px 10px #ffffff73, 5px 5px 10px rgba(94, 104, 121, 0.288),inset -5px -5px 10px #1d1a5854 ,inset 5px 5px 10px #ffffff73;

  /* display: flex; */
  align-items: center;
  /* justify-content: space-between; */
  background-image: linear-gradient(
    to bottom right,
    rgb(212, 212, 212),
    rgba(243, 240, 240, 0.644)
  );
}

.inner4 {
  border-radius: 25px;
  background-color: #dde1e7;
  box-shadow: -5px -5px 10px #ffffff73, 5px 5px 10px #322ea05e;

  display: flex;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(
    to bottom right,
    rgb(212, 212, 212),
    rgba(243, 240, 240, 0.644)
  );
}